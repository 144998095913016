// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/pic_success@2x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".explain[data-v-1fff0d0c]{width:100%}.title[data-v-1fff0d0c]{height:10.667vw;text-align:center;line-height:10.667vw;font-size:4vw;border-bottom:1px solid #ccc}.content[data-v-1fff0d0c]{width:100%;padding:0 10.667vw}.icon[data-v-1fff0d0c]{width:18.667vw;height:18.667vw;margin:5.333vw 30.4vw;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%}h3[data-v-1fff0d0c]{color:#1a1a1a;font-size:4vw}.text[data-v-1fff0d0c],h3[data-v-1fff0d0c]{text-align:center}.text[data-v-1fff0d0c]{color:#ccc;font-size:3.733vw}.checkMarket[data-v-1fff0d0c]{margin-top:10.667vw;width:100%;padding:2.4vw 0;text-align:center;color:#fff;background-color:#ff2d2e;border-radius:1.6vw;font-size:4vw}", ""]);
// Exports
module.exports = exports;
